<template>
  <app-teleport v-if="isActive && !!options.length">
    <aside
      class="input-suggestions"
      ref="modal"
      v-focus-navigate="{
        exitOnEsc: true,
        exitOnTop: true,
        exitCallback: () => $emit('close'),
      }"
      v-stick-to="{
        align: 'right',
        stick: 'left',
        placement: 'bottom',
        enableFullWidth: true,
        stickTo,
        element: modal,
      }"
      v-click-outside="{
        callback: () => $emit('close'),
      }"
    >
      <div
        v-for="option in options"
        tabindex="0"
        class="input-suggestions__option"
        @click="() => $emit('select', option)"
        @keydown.enter="() => $emit('select', option)"
        :key="option.id"
      >
        <cut-text :text="option.title" class="input-suggestions__title" />
        <cut-text v-if="option.explanation" :text="option.explanation" class="input-suggestions__explanation" />
      </div>
    </aside>
  </app-teleport>
</template>

<script setup lang="ts" generic="Option extends { id: string; title: string; explanation?: string }">
import { ComponentPublicInstance, ref, watch } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { vFocusNavigate } from "@horizon56/directives/focus-navigate";
import { vStickTo } from "@horizon56/directives/stick-to";

import CutText from "@/components/content/cut-text.vue";

const props = defineProps<{ isOpenTrigger: boolean; options: Option[]; stickTo?: ComponentPublicInstance }>();
defineEmits<{ (e: "close"): void; (e: "select", a: Option): void }>();

const isActive = ref(!!props.isOpenTrigger);
const modal = ref();

watch(
  () => props.isOpenTrigger,
  (is) => {
    window.requestAnimationFrame(() => {
      if (isInFocus()) {
        return;
      }
      isActive.value = is;
    });
  },
);

const isInFocus = () => modal.value?.contains?.(document.activeElement);

const updateIsActive = () => {
  if (!isInFocus() && !props.isOpenTrigger) {
    isActive.value = false;
  }
};

watch(
  () => isActive.value,
  () =>
    window.requestAnimationFrame(() => {
      if (isActive.value) {
        window.addEventListener("click", updateIsActive, { capture: true });
      }
    }),
);
</script>

<style lang="scss" scoped>
.input-suggestions {
  display: flex;
  flex-flow: column;
  background: var(--menu-bg);
  overflow: hidden;
  border-radius: var(--app-radius-medium);

  border: 1px solid var(--black-20);
  @include sticky-content;
  @include set-scrollbar();
  &__option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    user-select: none;
    padding: var(--app-spacing-size-medium);
    cursor: pointer;
    height: var(--app-button-height-large);
    @include focus-outline;
    &:hover {
      background: var(--menu-active);
    }
  }

  &__title:not(:last-child) {
    margin-right: var(--app-spacing-size-small);
  }
  &__explanation {
    color: var(--black-50);
    margin-left: auto;
  }
}
</style>
